import { createHashRouter, Navigate } from 'react-router-dom';
import { lazy, Suspense } from "react";
// import Home from "../pages/Home";
// import Layout from "@/layout";
// import PasswordRoom from "@/pages/PasswordRoom";
// import Chat from "@/pages/Chat";
// import Empty from "@/pages/Empty";
const Home = lazy(() => import('@/pages/Home'));
const Layout = lazy(() => import("@/layout"));
const Empty = lazy(() => import('@/pages/Empty'));
const Chat = lazy(() => import('@/pages/Chat'));

export default createHashRouter([
    {
        path: "/empty",
        element: <Empty/>
    },
    {
        path: '/',
        element: <Navigate to="/home" replace></Navigate>
    },
    {
        element: <Suspense fallback={ <div></div> }>
            <Layout></Layout>
        </Suspense>,
        children: [
            {
                path: '/home',
                element: <Home></Home>,
            },
            // {
            //     path: "/password-room/:groupID",
            //     element: <PasswordRoom/>
            // },
            {
                path: "/chat",
                children: [
                    {
                        index: true,
                        element: <div>no data</div>
                    },
                    {
                        path: ":conversationID",
                        element: <Chat/>
                    }
                ]
            }
        ]
    }
]);
