import { CustomConversationItem } from "@/store/slice/home";
import { SessionType } from "@chat/open-im-sdk";
import store from "@/store";

const env = process.env.ENV_FILE;

export const visibleGroupListIds = [
    "1187309918",
    "2618646220"
];

export function getConversationList(list: CustomConversationItem[]) {
    // 测试环境不做处理
    if (env === "test") {
        return list;
    }
    return list.filter(item => {
        if (item.conversationType === SessionType.Single) return true;
        return visibleGroupListIds.includes(item.groupID);
    });
}


// 获取未读数量，如果没有传入列表则使用内存里的
export function getVisibleUnReadCount(list?: CustomConversationItem[]) {
    const visibleList: CustomConversationItem[] = list ?? store.getState().home.list;
    return visibleList.reduce((total, item) => {
        if (item.conversationID) {
            return total + item.unreadCount;
        }
        return total;
    }, 0);
}
