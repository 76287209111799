import { Image, Mask, MaskProps } from "antd-mobile";
import errorIcon from "@/assets/svg/toast-error.svg";
import successIcon from "@/assets/svg/toast-success.svg";
import { ReactNode } from "react";

export type ToastType = "success" | "error";
export type ToastProps = {
    type?: ToastType;
    content?: ReactNode;
    duration?: number;
    visible?: boolean;
    getContainer?: MaskProps["getContainer"];
    afterClose?: () => void
}

function getIcon(type: ToastType) {
    const icons: Record<ToastType, string> = {
        error: errorIcon,
        success: successIcon
    };
    return icons[type] || successIcon;
}

export function InternalToast(props: ToastProps) {
    const { type = "error", content } = props;
    return <Mask
        visible={ props.visible }
        style={ { zIndex: 9999 } }
        afterClose={ props.afterClose }
        opacity={ 0.1 }>
        <div className="component-toast">
            <Image className="icon" src={ getIcon(type) }></Image>
            <div className="text">{ content }</div>
        </div>
    </Mask>;
}

export default InternalToast;
