// 音频
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    boxVisible: false,
    isRecording: false,
    duration: 0,
    formatTime: "00:00",
};
export const voiceSlice = createSlice({
    name: "voice",
    initialState,
    reducers: {
        setVoiceBoxVisible(state, action) {
            state.boxVisible = action.payload;
        },
        setTime(state, action) {
            const time = action.payload;
            const sconds = time / 1000;
            const formattedTime = [
                Math.floor((time % (60 * 60 * 1000)) / 60000), // minutes
                Math.floor((time % (60 * 1000)) / 1000), // seconds
            ]
                .map((v) => (v < 10 ? '0' + v : v));
            state.formatTime = formattedTime.join(":");
            state.duration = sconds;
        },
        resetVoice: () => initialState
    }
});
export const { setVoiceBoxVisible, setTime,resetVoice } = voiceSlice.actions;


export default voiceSlice.reducer;
