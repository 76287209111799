import { ToastProps, InternalToast } from "@/components/Toast/Toast";
import { mergeProps, ImperativeHandler, renderImperatively } from "@chat/shared";

let currentHandler: ImperativeHandler | null = null;
const defaultProps: ToastProps = {
    duration: 2000,
    type: "success"
};
let currentTimeout: NodeJS.Timeout | null = null;
export type ToastShowProps = Omit<ToastProps, 'visible'>
const ToastInner = (
    props: ToastShowProps & {
        onClose?: () => void
    }
) => <InternalToast { ...props } />;

export function show(p: ToastShowProps | string, config?: ToastProps) {
    config = config || {};
    const props = mergeProps(
        defaultProps,
        typeof p === 'string' ? { content: p } : p,
        config
    );
    const element = (
        <ToastInner
            { ...props }
        />);
    if (currentHandler) {
        currentHandler.replace(element);
    } else {
        currentHandler = renderImperatively(element);
    }
    if (currentTimeout) {
        clearTimeout(currentTimeout);
    }
    if (props.duration) {
        currentTimeout = setTimeout(() => {
            clear();
        }, props.duration);
    }
    return currentHandler as ImperativeHandler;
}


export function clear() {
    currentHandler?.close();
    currentHandler = null;
}
