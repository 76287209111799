import React, { useEffect } from 'react';
import { Provider } from "react-redux";
import { RouterProvider } from 'react-router-dom';
import router from "./router";
import store from "@/store";
import { ConfigProvider, SafeArea } from "antd-mobile";
import enUS from "antd-mobile/es/locales/en-US";
import "wc-waterfall";

function App() {
    useEffect(() => {
        const stop = (e: Event) => {
            e.preventDefault();
        };
        document.body.addEventListener("contextmenu", stop);
        return () => {
            document.body.removeEventListener("contextmenu", stop);
        };
    }, []);
    return (
        <>
            <SafeArea position="top"/>
            <Provider store={ store }>
                <ConfigProvider locale={ enUS }>
                    <RouterProvider router={ router }/>
                </ConfigProvider>
            </Provider>
            <SafeArea position="bottom"/>
        </>

    );
}

export default App;
