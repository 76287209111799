import { createHttp, TOKEN_TYPE, type TypeResponse } from "./http";

const http = createHttp(process.env.REACT_APP_IM_OTHER_URL!, TOKEN_TYPE.CHAT);
const apis = {
    history: "/group/prize/history",
    userFull: "/user/find/full",
    like: "/user/like",
    cancelLike: "/user/like/cancel",
    // 获取gif
    getGif: "/gif/list",
    // 获取密码房列表
    roomPrivate: "/room/list_private",
    // 申请密码房
    authRoom: "/room/auth",
    // 为所有人删除， 不走imsdk
    deleteMsgs: "/msg/delete_msgs",
    identity: "/user/identity/list"

};

// 获取历史获胜接口
export const getHistoryWin = () => {
    return http.post<any, TypeResponse<any>>(apis.history);
};
// 获取当前用户信息 /user/find/full
export const getUserFull = (params: { userId: string }) => {
    const { userId } = params;
    return http.post<any, TypeResponse<any>>(apis.userFull, {
        userIDs: [userId]
    });
};

// 点赞
export const setUserLike = (params: { userID: string, state: boolean }) => {
    const { userID, state } = params;
    return http.post<any, TypeResponse<any>>(state ? apis.cancelLike : apis.like, { userID });
};
// 获取gif图片
export const getGifImage = (params: { pageNumber: number, showNumber: number }) => {
    return http.post<any, TypeResponse<any>>(apis.getGif, {
        pagination: params
    });
};

// 获取密码房
export const getPasswordRoom = () => {
    return http.post<any, TypeResponse<any>>(apis.roomPrivate);
};
export const applyPasswordRoom = (params: {
    groupID: string,
    password: string
}) => {
    return http.post<any, TypeResponse<any>>(apis.authRoom, params);
};
// 清除指定会话的聊天记录
export const deleteForEveryone = (params: {
    conversationID: string;
    messages: { seqs: number, sendID: string }[]
}) => {
    return http.post<any, TypeResponse<any>>(apis.deleteMsgs, params);
};
type IdentityItem = {
    identity: number;
    onlineStatus: number;
    userID: string;
}

export const getIdentity = () => {
    return http.post<any, TypeResponse<IdentityItem[]>>(apis.identity);
};
