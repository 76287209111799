import { createSlice } from "@reduxjs/toolkit";
import { CardItemItem } from "@/utils";

const NAME = "followed";
export type TGameCode =
    | 'dice'
    | 'color'
    | '3Digit'
    | 'scratch'
    | 'matka'
    | 'kerala'
    | 'stateLottery'
    | 'quick3d'
    | 'quickRace'
    | 'casino'
    | 'live'
    | 'sport'
    | 'quickStateLottery'
    | "satta"
const initialState = {
    visible: false,
    // 判断是否开奖
    isEnd: false,
    followedData: {
        gameIconUrl: "",
        gameName: "",
        issNo: "",
        gameType: "" as TGameCode,
        openTime: 0,
        result: "",
        rawData: {} as any,
        totalAmount: 0,
        totalAwardAmount: 0,
        list: [] as CardItemItem[],
        shareId: "",
        shareName: "",
        totalCount: 0,
    }
};
// 获取当前详细信息
export const followed = createSlice({
    name: NAME,
    initialState,
    reducers: {
        showModal(state) {
            state.visible = true;
        },
        setFollowedModalVisible(state, action) {
            state.visible = action.payload;
        },
        updateFollowed(state, action) {
            state.followedData = action.payload;
        },
        // 点击打开跟投弹框
        openFollowedModal(state, action) {
            state.visible = true;
            state.isEnd = false;
            state.followedData = action.payload;
        },
        // 打开palynow 弹框
        openEndModal(state, action) {
            state.visible = true;
            state.isEnd = true;
            state.followedData = action.payload;
        },
    }
});
export const {
    setFollowedModalVisible,
    showModal,
    updateFollowed,
    openFollowedModal,
    openEndModal
} = followed.actions;
export default followed.reducer;
