import { show, clear } from "./methods";

export type { ToastType, ToastProps } from "./Toast";
export type { ToastShowProps } from "./methods";
const Toast = {
    show,
    clear
};

export default Toast;
